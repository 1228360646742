import React from "react";
import './topbar.scss';
import constants from "js/constants";
import phoneImage from 'assets/svg/phone.svg';
import routeUtils from 'js/routerUtils';
import axios from "axios";

class TopBar extends React.Component {
    constructor() {
        super();
        this.state = {
            dyPhone: constants.PHONE_NUMBER,
            dyPhoneBusiness: constants.PHONE_BUSINESS,
            menuList: [{
                id: 0,
                'title': '首页',
                route: '/home'
            }, {
                id: 1,
                'title': '产品功能',
                route: '/product'
            }, {
                id: 2,
                'title': '客户案例',
                route: '/customer'
            }, {
                id: 3,
                'title': '开放平台',
                route: '/developer'
            }, {
                id: 4,
                'title': '关于我们',
                route: '/about'
            }],
            toWhite: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true) // 监听页面滚动
        const dyPhoneItem = window.localStorage.getItem('DAYOUDAN_PHONE_LIST');
        if (dyPhoneItem) {
            try {
                const phoneObj = JSON.parse(dyPhoneItem);
                if (phoneObj.localUpdateTime && new Date().getTime() - phoneObj.localUpdateTime < 300000) {
                    this.setState({
                        dyPhone: phoneObj.dyPhone,
                        dyPhoneBusiness: phoneObj.businessPhone
                    })
                } else {
                    this.loadPhone();
                }
            } catch (e) {
                this.loadPhone();
            }
        } else {
            this.loadPhone();
        }
    }

    loadPhone() {
        axios({
            url: 'https://res.oss.hz.dayoudan.com/mini-app-help-phones.json?t=' + new Date().getTime(),
            method: 'GET',
            params: {},
        }).then(res => {
            if (res && res.status == 200 && res.data) {
                res.data.localUpdateTime = new Date().getTime();
                window.localStorage.setItem('DAYOUDAN_PHONE_LIST', JSON.stringify(res.data));
                this.setState({
                    dyPhone: res.data.dyPhone,
                    dyPhoneBusiness: res.data.businessPhone
                })
            }
        }).catch(err => {
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = (e) => {
        if (e.target.scrollTop > 10) {
            this.setState({
                toWhite: true,
            })
        } else {
            this.setState({
                toWhite: false,
            })
        }
    }

    onTabClick(item, index) {
        routeUtils.routeTo(item.route);
    }

    toHome() {
        routeUtils.routeTo('/');
    }

    toReg() {
        routeUtils.openPage(constants.REG_DAYOUDAN)
    }

    toLogin() {
        routeUtils.openPage(constants.DAYOUDAN)
    }

    render() {
        const { menuList, toWhite, dyPhone, dyPhoneBusiness } = this.state;
        return (
            <div className={`top-bar-wraper ${toWhite ? 'shadow-deep' : 'shadow-line'}`} style={{ overflow: 'hidden' }}>
                <div className="flex align-center top-bar">
                    <img onClick={this.toHome.bind(this)} src={require('../../assets/dayou_logo.png')} className="top-dy-logo hand"></img>
                    <div className="flex-sub flex" style={{ height: '100%' }}>
                        {
                            menuList.map(
                                (menu, index) => {
                                    return (
                                        <div key={menu.id} className="top-tab-block text-df hand flex-on-center" onClick={this.onTabClick.bind(this, menu, index)}>
                                            <div className="flex flex-direction justify-between align-center" style={{ height: '100%' }}>
                                                <div className="flex-sub flex-on-center">
                                                    {menu.title}
                                                </div>
                                                <div className={`tab-line ${this.props.tabIndex == index ? '' : 'v-hidden'}`}></div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    <div className="text-sm padding-xs main-color">
                        <div className="flex-align-center">
                            <img src={require('../../assets/top_bar_call.png')} className="phone-img"></img>
                            <span className="margin-left-xs">客服电话</span>
                            <span className="margin-left-sm bold">{dyPhone}</span>
                        </div>
                        <div className="flex-align-center" >
                            <img src={require('../../assets/top_bar_business.png')} className="phone-img"></img>
                            <span className="margin-left-xs">商务合作</span>
                            <span className="margin-left-sm bold">{dyPhoneBusiness}</span>
                        </div>
                    </div>
                    {/* <div className="main-color phone-call flex-align-center">
                        <img src={phoneImage} className="phone-img"></img>
                        <span className="margin-left-xs">{constants.PHONE_NUMBER}</span>
                    </div> */}
                    <div className="btn-reg margin-left-xs hand" onClick={this.toReg.bind(this)}>免费注册</div>
                    <div className="btn-login margin-left-xs hand" onClick={this.toLogin.bind(this)}>登录</div>
                </div>
            </div>

        )
    }
}

export default TopBar;