import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import constants from 'js/constants';
import routeUtils from 'js/routerUtils';
import TopbarWight from 'h5/compontnts/topbar/topbar';
import BannerTextAbout from 'assets/h5/banner_text_about.svg';
import { Button } from 'antd';
import btnEnterSvg from 'assets/h5/btn_enter.svg';

import BottomCallBar from 'h5/compontnts/bottomCallBar/bottomCallBar';
import phoneGreen from 'assets/h5/phone_green.svg';
import PartnersWight from 'h5/compontnts/partners';
import BottomBarMobWidget from 'h5/compontnts/bottombar/bottombar';
import axios from 'axios';

class AboutMobileWight extends BaseMobileComponents {
    constructor() {
        super();
        this.state = {
            dyPhone: constants.PHONE_NUMBER,
            dyPhoneBusiness: constants.PHONE_BUSINESS,
            skills: [{
                title: '订单同步',
                content: '同步订单至大有单，自动就近自动分单',
                icon: 'dy_order_desc1.png'
            }, {
                title: '配送回传',
                content: '支持状态订阅，配送状态变化实时回传',
                icon: 'dy_order_desc2.png'
            }, {
                title: '骑士位置',
                content: '实时获取骑士位置，回显到自有系统',
                icon: 'dy_order_desc3.png'
            }],
            stepList: [{
                iconTop: 'module_three_icon1.png',
                iconBottom: 'module_three_step1_order.png',
            }, {
                iconTop: 'module_three_icon2.png',
                iconBottom: 'module_three_step2.png',
            }, {
                iconTop: 'module_three_icon3.png',
                iconBottom: 'module_three_step3.png',
            }, {
                iconTop: 'module_three_icon4.png',
                iconBottom: 'module_three_step4.png',
            },],
            envs: [{
                img: require('assets/home_friend_env1.png'),
                title: '业务支撑',
                content: '针对行业定制特有方案',
            }, {
                img: require('assets/home_friend_env2.png'),
                title: '系统稳健',
                content: '日百万级的订单处理能力，保障核心流程便捷操作',
            }, {
                img: require('assets/home_friend_env3.png'),
                title: '服务保障',
                content: '提供1对1的服务对接链路',
            }, {
                img: require('assets/home_friend_env4.png'),
                title: '技术支持',
                content: '协助开发者拉通技术路径，全方位专业服务',
            },],
        };
    }

    loadPhone() {
        axios({
            url: 'https://res.oss.hz.dayoudan.com/mini-app-help-phones.json?t=' + new Date().getTime(),
            method: 'GET',
            params: {},
        }).then(res => {
            if (res && res.status == 200 && res.data) {
                res.data.localUpdateTime = new Date().getTime();
                window.localStorage.setItem('DAYOUDAN_PHONE_LIST', JSON.stringify(res.data));
                this.setState({
                    dyPhone: res.data.dyPhone,
                    dyPhoneBusiness: res.data.businessPhone
                })
            }
        }).catch(err => {
        })
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
    }

    toDownload() {
        routeUtils.routeTo('/app');
    }

    componentDidMount() {
        const dyPhoneItem = window.localStorage.getItem('DAYOUDAN_PHONE_LIST');
        if (dyPhoneItem) {
            try {
                const phoneObj = JSON.parse(dyPhoneItem);
                if (phoneObj.localUpdateTime && new Date().getTime() - phoneObj.localUpdateTime < 300000) {
                    this.setState({
                        dyPhone: phoneObj.dyPhone,
                        dyPhoneBusiness: phoneObj.businessPhone
                    })
                } else {
                    this.loadPhone();
                }
            } catch (e) {
                this.loadPhone();
            }
        } else {
            this.loadPhone();
        }
    }

    renderView() {
        const { dyPhoneBusiness, dyPhone } = this.state;
        return (
            <div className="root-view-mob-about bg-white">
                <div className="banner-view">
                    <TopbarWight></TopbarWight>
                    <img src={BannerTextAbout} className='banner-text'></img>
                    <div className='sub-text'>全渠道聚合订单管理&配送服务系统，聚合配送运力供应商，轻松管理所有订单，一键发起多运力配送，全链路解决订单、库存、配送、运单、售后问题</div>
                    <div>
                        <Button type='primary' style={{ marginTop: 8 }}>
                            <div className='flex-align-center' onClick={this.toDownload.bind(this)}>免费注册
                                <img src={btnEnterSvg} className='margin-left-xs'></img></div>
                        </Button>
                    </div>
                </div>
                <div className='second-part'>
                    <div className='common-inner-text-1 inner-text-1'>公司介绍</div>
                    <div className='inner-image-wraper'>
                        <img src={require('assets/h5/building.png')} className='inner-image'></img>
                    </div>
                    <p className='text-left'>湖南迪嘉科技有限公司是一家专注于智能同城聚合配送SAAS服务平台及系列应用系统产品研究的国家高新技术企业、国家科技型中小企业、国家规上企业、湖南省专精特新企业，通过ISO9001质量认证。公司注册资金1000万，自成立以来，深耕于同城聚合即时配送、即时服务等领域，坚持自主研发、设计、生产、销售，不断推出基于人工智能、大数据分析、云计算技术的同城聚合即时配送信息化行业解决方案，拥有发明专利两项，获得饿了么2023年度优秀服务商荣誉。</p>
                    <p className='text-left'>迪嘉科技“大有单——场景式聚合订单全链路管理SAAS平台”是聚合全网电商渠道、同城配送运力平台，可以一键呼叫顺丰、达达、美团、蜂鸟、UU、闪送等全国十余家运力骑手，城市覆盖全，区域运力强，各平台同时抢单、各平台同时比价、配送又快又省钱。服务的客户群体为全国有外卖业务的商家，覆盖商业集团、连锁商户、中小商户等，商户类别覆盖有餐饮美食、烘焙蛋糕、龙虾烧烤、甜品饮品、浪漫鲜花、果蔬生鲜、超市便利、夜宵小吃和异国料理等。</p>
                </div>
                <div className='third-part'>
                    <div className='common-inner-text-1 inner-text-1'>业务范围</div>
                    <div className='common-inner-text-2'>无论身在何处，都能获得大有单一键发单的便利服务</div>
                    <img src={require('assets/h5/about_map.png')} className='inner-img'></img>
                </div>
                <div className='fifth-part'>
                    <PartnersWight></PartnersWight>
                </div>
                <div className='fouth-part'>
                    <span className='inner-text'>联系我们</span>
                    <div className='inner-img-wraper'>
                        <img src={require('assets/h5/about_location.png')} className='inner-img'></img>
                    </div>
                    <div className="bottom-container-right">
                        <div className="flex-align-center text-left bottom-container-right-item">
                            <img src={require('assets/map_icon_addr.png')} className="map-icon" />
                            <div className="bottom-container-right-item-right">
                                地址
                                <div className="bottom-container-right-item-right-desc">湖南省长沙市岳麓区世贸希尔顿大楼12楼</div>
                            </div>
                        </div>
                        <div className="flex-align-center text-left bottom-container-right-item">
                            <img src={require('assets/map_icon_email.png')} className="map-icon" />
                            <div className="bottom-container-right-item-right">
                                邮箱
                                <div className="bottom-container-right-item-right-desc">cs@nadayou.com</div>
                            </div>
                        </div>
                        <div className="flex-align-center text-left bottom-container-right-item">
                            <img src={require('assets/map_icon_call.png')} className="map-icon" />
                            <div className="bottom-container-right-item-right">
                                电话
                                <div className="bottom-container-right-item-right-desc">{dyPhone}</div>
                            </div>
                        </div>
                        <div className="flex-align-center text-left bottom-container-right-item">
                            <img src={require('assets/about/zs.png')} className="map-icon" />
                            <div className="bottom-container-right-item-right">
                                商务合作
                                <div className="bottom-container-right-item-right-desc">{dyPhoneBusiness}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomBarMobWidget></BottomBarMobWidget>
                <BottomCallBar></BottomCallBar>
            </div>
        )
    }
}

export default AboutMobileWight;